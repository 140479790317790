import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState: Configurator[] = [];

const getItemIndex = (state: Configurator[], idToFind: number): number => {
  const ids = state.map(item => item.id);
  return ids.indexOf(idToFind);
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clear: () => {
      return [];
    },
    addItem: (state: any, action: PayloadAction<Configurator>) => {
      state.push(action.payload);
    },
    removeItem: (state: any, action: PayloadAction<number>) => {
      return state.filter((x: { id: number; }) => x.id !== action.payload)
    },
    increaseCount: (state: any, action: PayloadAction<number>) => {
      const itemIndex = getItemIndex(state, action.payload);
      state[itemIndex].count += 1;
    },
    decreaseCount: (state: any, action: PayloadAction<number>) => {
      const itemIndex = getItemIndex(state, action.payload);

      if (state[itemIndex].count === 1) {
        return state.filter((x: { id: number; }) => x.id !== action.payload)
      } else {
        state[itemIndex].count -= 1;
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // builder
    //   .addCase(incrementAsync.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(incrementAsync.fulfilled, (state, action) => {
    //     state.status = 'idle';
    //     state.value += action.payload;
    //   });
  },
});

export const { addItem, removeItem, increaseCount, decreaseCount, clear } = cartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectContent = (state: RootState) => state.cart;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default cartSlice.reducer;
