import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const emptyConfigurator: Configurator = {
  id: -1,
  category: "",
  materialId: 0,
  material: "",
  materialType: "",
  length: "",
  width: "",
  thickness: 0,
  image: "",
  count: 0,
  edgeType: "",
  roundedCorners: false,
  visibleFrontEdge: false,
  visibleBackEdge: false,
  visibleLeftEdge: false,
  visibleRightEdge: false,
  holesUnderSink: "",
  holesAppliedSink: "", 
  holesSiphon: "",
  holesFaucet: "",
  materialImage: "",
  plinth: false,
  measurings: false,
  fitting: false,
  delivery: false,
  edgePrice: 0,
  profiles: [],
  materialVariants: [],
  materialPrice: 0,
  pricePerUnit: 0,
  maxWidth: 0,
  maxLength: 0
};

const initialState: Configurator = {
  ...emptyConfigurator,
};

export const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    reset: () => {
      return {
        ...emptyConfigurator,
      };
    },
    changeCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    changeConfiguratorContent: (state, action: PayloadAction<Configurator>) => {
      return { ...action.payload };
    },
    changeThickness: (state, action: PayloadAction<number>) => {
      state.thickness = action.payload;
    },
    changeLength: (state, action: PayloadAction<string>) => {
      state.length = action.payload;
    },
    toggleProfile: (state, action: PayloadAction<any>) => {
      let newObject;

      if (Object.keys(state.profiles).includes(action.payload["profile"])) {
        newObject = { ...state.profiles };
        delete newObject[action.payload["profile"]];
      } else {
        newObject = { ...state.profiles, [action.payload["profile"]]: action.payload["price"] };
      }

      state.profiles = newObject;
    },
    changeWidth: (state, action: PayloadAction<string>) => {
      state.width = action.payload;
    },
    changeMaxWidth: (state, action: PayloadAction<number>) => {
      state.maxWidth = action.payload;
    },
    changeMaxLength: (state, action: PayloadAction<number>) => {
      state.maxLength = action.payload;
    },
    changeMaterial: (state, action: PayloadAction<string>) => {
      state.material = action.payload;
      state.thickness = 0;
    },
    changeMaterialType: (state, action: PayloadAction<string>) => {
      state.materialType = action.payload;
      state.thickness = 0;
    },
    changeEdgeType: (state, action: PayloadAction<string>) => {
      state.edgeType = action.payload;
    },
    changeEdgePrice: (state, action: PayloadAction<number>) => {
      state.edgePrice = action.payload;
    },
    toggleRoundedCorners: (state) => {
      state.roundedCorners = !state.roundedCorners;
    },
    toggleVisibleFrontEdge: (state) => {
      state.visibleFrontEdge = !state.visibleFrontEdge;
    },
    toggleVisibleBackEdge: (state) => {
      state.visibleBackEdge = !state.visibleBackEdge;
    },
    toggleVisibleLeftEdge: (state) => {
      state.visibleLeftEdge = !state.visibleLeftEdge;
    },
    toggleVisibleRightEdge: (state) => {
      state.visibleRightEdge = !state.visibleRightEdge;
    },
    changeHolesUnderSink: (state, action: PayloadAction<string>) => {
      state.holesUnderSink = action.payload;
    },
    changeHolesAppliedSink: (state, action: PayloadAction<string>) => {
      state.holesAppliedSink = action.payload;
    },
    changeHolesSiphon: (state, action: PayloadAction<string>) => {
      state.holesSiphon = action.payload;
    },
    changeHolesFaucet: (state, action: PayloadAction<string>) => {
      state.holesFaucet = action.payload;
    },
    changeMaterialImage: (state, action: PayloadAction<string>) => {
      state.materialImage = action.payload;
    },
    changeMaterialId: (state, action: PayloadAction<number>) => {
      state.materialId = action.payload;
    },
    togglePlinth: (state) => {
      state.plinth = !state.plinth;
    },
    toggleMeasurings: (state) => {
      state.measurings = !state.measurings;
    },
    toggleFitting: (state) => {
      state.fitting = !state.fitting;
    },
    toggleDelivery: (state) => {
      state.delivery = !state.delivery;
    },
    changeMaterialVariants: (state, action: PayloadAction<any>) => {
      state.materialVariants = action.payload;
    },
    changeMaterialPrice: (state, action: PayloadAction<number>) => {
      state.materialPrice = action.payload;
    }
  },
});

export const {
  reset,
  changeCategory,
  changeEdgeType,
  changeThickness,
  changeLength,
  changeMaterial,
  changeMaterialType,
  changeWidth,
  toggleRoundedCorners,
  toggleVisibleBackEdge,
  toggleVisibleFrontEdge,
  toggleVisibleLeftEdge,
  changeConfiguratorContent,
  toggleVisibleRightEdge,
  changeHolesAppliedSink,
  changeHolesFaucet,
  changeEdgePrice,
  changeHolesSiphon,
  changeHolesUnderSink,
  changeMaterialImage,
  changeMaterialId,
  togglePlinth,
  toggleMeasurings,
  toggleFitting,
  toggleDelivery,
  toggleProfile,
  changeMaterialVariants,
  changeMaterialPrice,
  changeMaxLength,
  changeMaxWidth
} = configuratorSlice.actions;

export const selectConfigurator = (state: RootState) => state.configurator;

export default configuratorSlice.reducer;
