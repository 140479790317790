import "./App.css";
import TopNavbar from "./components/layout/TopNavbar/TopNavbar";
import Footer from "./components/layout/Footer/Footer";

import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const MaterialsDisplay = lazy(
  () => import("./components/materials-display/MaterialsDisplay")
);
const Cart = lazy(() => import("./components/cart/Cart"));
const Configurator = lazy(
  () => import("./components/configurator/Configurator")
);
const Landing = lazy(() => import("./components/landing/Landing"));

function App() {
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    fetch("/api/materialtypes")
      .then((res) => res.json())
      .then((data) => {
        setMaterials(data);
      });
  }, []);

  return (
    <div className="App">
      <Router>
        <TopNavbar />
        <Suspense fallback={null}>
          <Switch>
            {materials.map((material: { [key: string]: any }) => {
              let path = `/${material["Name"]
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")}`;
              return (
                <Route path={path} key={`path-route-${material["Name"]}`}>
                  <MaterialsDisplay
                    materialId={material["Id"]}
                    materialName={material["Name"]}
                  />
                </Route>
              );
            })}
            <Route path={"/cart"}>
              <Cart />
            </Route>
            <Route path={"/calculator"}>
              <Configurator />
            </Route>
            <Route exact path="/">
              <Landing />
            </Route>
          </Switch>
        </Suspense>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
