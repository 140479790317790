import logo from "../../../logo.png";
import "./TopNavbar.css";

import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAppSelector } from "../../../app/hooks";
import {
  selectContent
} from "../../../features/cart/cartSlice";

function TopNavbar() {
  const content = useAppSelector(selectContent);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="https://aerolith.ro/">
          <img
            loading="lazy" 
            src={logo}
            height="70"
            className="d-inline-block align-top"
            alt="Aerolith logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100">
            <Nav.Link href="https://aerolith.ro/" className="navbar-li">
              Acasă
            </Nav.Link>
            {/* <NavDropdown
              className="navbar-li"
              title="Materiale"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} className="navbar-li" to="/cuart">
                Cuarț
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} className="navbar-li" to="/granit">
                Granit
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} className="navbar-li" to="/neolith">
                Neolith
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} className="navbar-li" to="/marmura">
                Marmură
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link as={Link} to="/calculator" className="navbar-li">
              Calculator
            </Nav.Link>
            <Nav.Link as={Link} to="/cart" className="ms-auto">
              <div className="cart-container position-relative d-inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-cart"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                {
                  content.length > 0 ?
                    <span className="cart-basket d-flex align-items-center justify-content-center">{content.length}</span> : ""
                }
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
